var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full mb-6 grid grid-cols-12 gap-x-6 gap-y-5" },
    [
      _c("label", { staticClass: "col-span-2 my-auto text-right text-sm" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm.value.length > 0
        ? [
            _vm._l(_vm.value, function (award, key) {
              return [
                _c(
                  "div",
                  {
                    key: "name-" + key,
                    staticClass: "col-start-3 col-span-10",
                  },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          award.translations[_vm.$i18n.locale].name +
                            "(" +
                            _vm.convertAwardDate(award) +
                            ")"
                        )
                      ),
                    ]),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }