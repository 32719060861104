var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu == "detail"
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-12 gap-x-6" },
            [
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.film_name") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.movie.translations[_vm.$i18n.locale].film_name
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.film_name_yomi") } },
                [_vm._v(_vm._s(_vm.movie.film_name_yomi))]
              ),
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.original_name") } },
                [_vm._v(_vm._s(_vm.movie.original_name))]
              ),
              _c("FlMovieItem", {
                attrs: {
                  label: _vm.$t("commons.fields.film_type"),
                  value: _vm.movie.film_type,
                  options: _vm.filmType,
                },
              }),
              _c("FlMovieItem", {
                attrs: {
                  label: _vm.$t("commons.fields.production_country"),
                  value: _vm.movie.production_country,
                  options: _vm.productionCountry,
                },
              }),
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.production_year") } },
                [_vm._v(_vm._s(_vm.movie.production_year))]
              ),
              _c("FlMovieItem", {
                attrs: {
                  label: _vm.$t("commons.fields.language"),
                  value: _vm.movie.language,
                  options: _vm.language,
                },
              }),
              _c("FlMovieItem", {
                attrs: {
                  label: _vm.$t("commons.fields.format"),
                  value: _vm.movie.format,
                  options: _vm.format,
                },
              }),
              _c("FlMovieItem", {
                attrs: {
                  label: _vm.$t("commons.fields.type"),
                  value: _vm.movie.type,
                  options: _vm.type,
                },
              }),
            ],
            1
          ),
          _c("fl-show-genre", {
            attrs: { areaClass: "col-span-2 mb-6", areaGrid: 12, labelGrid: 2 },
            model: {
              value: _vm.movie.genres,
              callback: function ($$v) {
                _vm.$set(_vm.movie, "genres", $$v)
              },
              expression: "movie.genres",
            },
          }),
          _c("fl-show-genre", {
            attrs: {
              isMainGenre: false,
              areaClass: "col-span-2 mb-6",
              areaGrid: 12,
              labelGrid: 2,
            },
            model: {
              value: _vm.movie.genres,
              callback: function ($$v) {
                _vm.$set(_vm.movie, "genres", $$v)
              },
              expression: "movie.genres",
            },
          }),
          _c(
            "div",
            { staticClass: "grid grid-cols-12 gap-x-6" },
            [
              _vm.isMovieTypeTv
                ? [
                    _c(
                      "FlMovieItem",
                      {
                        attrs: { label: _vm.$t("commons.fields.season_name") },
                      },
                      [_vm._v(_vm._s(_vm.movie.season_name))]
                    ),
                    _c(
                      "FlMovieItem",
                      {
                        attrs: {
                          label: _vm.$t("commons.fields.episode_number"),
                        },
                      },
                      [_vm._v(_vm._s(_vm.movie.episode_number))]
                    ),
                    _c(
                      "FlMovieItem",
                      {
                        attrs: {
                          label: _vm.$t("commons.fields.episode_name_first"),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.movie.translations[_vm.$i18n.locale]
                                .episode_name
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.original_from") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.movie.translations[_vm.$i18n.locale].original_from
                      ) +
                      " "
                  ),
                ]
              ),
              _c("FlMovieItem", {
                attrs: {
                  label: _vm.$t("commons.fields.rating"),
                  value: _vm.movie.rating,
                  options: _vm.rating,
                },
              }),
              _vm.movie.main_video.languages
                ? _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.subtitle_language_main"),
                      gridCols: 6,
                      value: _vm.movie.main_video.languages.subtitles,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.fullLanguageName(item.language)) +
                                  " "
                              ),
                              item.embedded
                                ? [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm.$t("pages.commons.quotes.baked")
                                        ) +
                                        ")"
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm.subtitleFormatName(
                                            item.file_format
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      935899414
                    ),
                  })
                : _vm._e(),
              _vm.movie.main_video.languages
                ? _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.voiceover_language_main"),
                      gridCols: 6,
                      value: _vm.movie.main_video.languages.voiceovers,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.fullLanguageName(item.language)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3364134218
                    ),
                  })
                : _vm._e(),
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.original_site") } },
                [_vm._v(_vm._s(_vm.movie.original_site))]
              ),
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t("commons.fields.twitter_url"),
                    value: _vm.movie.twitter_url,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.movie.twitter_url) + " ")]
              ),
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t("commons.fields.instagram_url"),
                    value: _vm.movie.instagram_url,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.movie.instagram_url) + " ")]
              ),
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t("commons.fields.facebook_url"),
                    value: _vm.movie.facebook_url,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.movie.facebook_url) + " ")]
              ),
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t("commons.fields.imdb_url"),
                    value: _vm.movie.imdb_url,
                  },
                },
                [_vm._v(_vm._s(_vm.movie.imdb_url))]
              ),
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t("commons.fields.copyright"),
                    textClass: "text-left break-words mb-6",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.movie.translations[_vm.$i18n.locale].copyright
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t("commons.fields.play_time"),
                    unit: "min",
                  },
                },
                [_vm._v(_vm._s(_vm.movie.play_time))]
              ),
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t("commons.fields.isan_number"),
                    value: _vm.movie.isan_number,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.movie.isan_number) + " ")]
              ),
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.content_note") } },
                [_vm._v(_vm._s(_vm.movie.content_note))]
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }