var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full mb-6 grid grid-cols-12 gap-x-6 gap-y-5" },
    [
      _c("label", { staticClass: "col-span-2 my-auto text-right text-sm" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm.value.length > 0
        ? [
            _vm._l(_vm.value, function (history, key) {
              return [
                _c(
                  "div",
                  { key: "name-" + key, staticClass: "col-start-3 col-span-4" },
                  [
                    history.name && history.name.length > _vm.modalShowLength
                      ? _c("p", { staticClass: "field truncate break-words" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                history.translations[_vm.$i18n.locale].name
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "p",
                          {
                            staticClass: "field break-words",
                            class: _vm.fieldClass,
                          },
                          [
                            _vm._v(
                              _vm._s(
                                history.translations[_vm.$i18n.locale].name
                              )
                            ),
                          ]
                        ),
                  ]
                ),
                _c(
                  "div",
                  { key: "started-" + key, staticClass: "col-span-4 relative" },
                  [
                    _c(
                      "span",
                      { staticClass: "text-xxs text-left absolute sub-label" },
                      [_vm._v(_vm._s(_vm.$t("commons.award.history_period")))]
                    ),
                    _c("p", { staticClass: "field", class: _vm.fieldClass }, [
                      _vm._v(_vm._s(_vm.convertHistoryDate(history))),
                    ]),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }