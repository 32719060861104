var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu == "outline"
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "w-full grid grid-cols-12 gap-x-6 relative" },
            [
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.outline") } },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.movie.translations[_vm.$i18n.locale].outline
                      ),
                    },
                  }),
                ]
              ),
              _c(
                "FlMovieItem",
                { attrs: { label: _vm.$t("commons.fields.pr_message") } },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.movie.translations[_vm.$i18n.locale].pr_message
                      ),
                    },
                  }),
                ]
              ),
              ["manager", "consumer"].includes(_vm.loginUserRole)
                ? [
                    _c(
                      "FlMovieItem",
                      { attrs: { label: _vm.$t("commons.fields.sns_note") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.movie.translations[_vm.$i18n.locale].sns_note
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "FlMovieItem",
                      { attrs: { label: _vm.$t("commons.fields.news_note") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.movie.translations[_vm.$i18n.locale].news_note
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "FlMovieItem",
                      { attrs: { label: _vm.$t("commons.fields.other_note") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.movie.translations[_vm.$i18n.locale]
                                .other_note
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "grid grid-cols-2 gap-x-6" }, [
            _c("div", { staticClass: "col-span-1" }, [
              _c(
                "div",
                { staticClass: "grid grid-cols-6 gap-x-6" },
                [
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.film_name"),
                        gridCols: 6,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.movie.translations[_vm.$i18n.locale].film_name
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.film_name_yomi"),
                        gridCols: 6,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.movie.film_name_yomi) + " ")]
                  ),
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.original_name"),
                        gridCols: 6,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.movie.original_name) + " ")]
                  ),
                  _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.film_type"),
                      value: _vm.movie.film_type,
                      options: _vm.filmType,
                      gridCols: 6,
                    },
                  }),
                  _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.production_country"),
                      value: _vm.movie.production_country,
                      options: _vm.productionCountry,
                      gridCols: 6,
                    },
                  }),
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.production_year"),
                        gridCols: 6,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.movie.production_year) + " ")]
                  ),
                  _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.language"),
                      value: _vm.movie.language,
                      options: _vm.language,
                      gridCols: 6,
                    },
                  }),
                  _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.format"),
                      value: _vm.movie.format,
                      options: _vm.format,
                      gridCols: 6,
                    },
                  }),
                  _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.type"),
                      value: _vm.movie.type,
                      options: _vm.type,
                      gridCols: 6,
                    },
                  }),
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.copyright"),
                        gridCols: 6,
                        textClass: "text-left break-words mb-6",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.movie.translations[_vm.$i18n.locale].copyright
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-span-1" }, [
              _c(
                "div",
                { staticClass: "grid grid-cols-6 gap-x-6" },
                [
                  _vm.isMovieTypeTv
                    ? [
                        _c(
                          "FlMovieItem",
                          {
                            attrs: {
                              label: _vm.$t("commons.fields.season_name"),
                              gridCols: 6,
                            },
                          },
                          [_vm._v(_vm._s(_vm.movie.season_name))]
                        ),
                        _c(
                          "FlMovieItem",
                          {
                            attrs: {
                              label: _vm.$t("commons.fields.episode_number"),
                              gridCols: 6,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.movie.episode_number) + " ")]
                        ),
                        _c(
                          "FlMovieItem",
                          {
                            attrs: {
                              label: _vm.$t(
                                "commons.fields.episode_name_first"
                              ),
                              gridCols: 6,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.movie.translations[_vm.$i18n.locale]
                                    .episode_name
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _c("FlMovieItem", {
                    attrs: {
                      label: _vm.$t("commons.fields.rating"),
                      value: _vm.movie.rating,
                      options: _vm.rating,
                      gridCols: 6,
                    },
                  }),
                  _vm.movie.main_video.languages
                    ? _c("FlMovieItem", {
                        attrs: {
                          label: _vm.$t(
                            "commons.fields.subtitle_language_main"
                          ),
                          gridCols: 6,
                          value: _vm.movie.main_video.languages.subtitles,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.fullLanguageName(item.language)
                                      ) +
                                      " "
                                  ),
                                  item.embedded
                                    ? [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.$t(
                                                "pages.commons.quotes.baked"
                                              )
                                            ) +
                                            ")"
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.subtitleFormatName(
                                                item.file_format
                                              )
                                            ) +
                                            ")"
                                        ),
                                      ],
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          935899414
                        ),
                      })
                    : _vm._e(),
                  _vm.movie.main_video.languages
                    ? _c("FlMovieItem", {
                        attrs: {
                          label: _vm.$t(
                            "commons.fields.voiceover_language_main"
                          ),
                          gridCols: 6,
                          value: _vm.movie.main_video.languages.voiceovers,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.fullLanguageName(item.language)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3364134218
                        ),
                      })
                    : _vm._e(),
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.original_site"),
                        gridCols: 6,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.movie.original_site) + " ")]
                  ),
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.imdb_url"),
                        gridCols: 6,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.movie.imdb_url) + " ")]
                  ),
                  _c(
                    "FlMovieItem",
                    {
                      attrs: {
                        label: _vm.$t("commons.fields.play_time"),
                        gridCols: 6,
                        unit:
                          _vm.movie.film_type === 1
                            ? "min (" +
                              _vm.$t("commons.explanation.tv_show_time") +
                              ")"
                            : "min",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.movie.play_time) + " ")]
                  ),
                ],
                2
              ),
            ]),
          ]),
          _c("fl-show-genre", {
            attrs: { areaClass: "col-span-2 mb-6", areaGrid: 12, labelGrid: 2 },
            model: {
              value: _vm.movie.genres,
              callback: function ($$v) {
                _vm.$set(_vm.movie, "genres", $$v)
              },
              expression: "movie.genres",
            },
          }),
          _c("fl-show-genre", {
            attrs: {
              isMainGenre: false,
              areaClass: "col-span-2 mb-6",
              areaGrid: 12,
              labelGrid: 2,
            },
            model: {
              value: _vm.movie.genres,
              callback: function ($$v) {
                _vm.$set(_vm.movie, "genres", $$v)
              },
              expression: "movie.genres",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }