var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu == "data" && _vm.loginUserRole == "manager"
    ? _c("section", [
        _c("div", { staticClass: "py-8 bg-very-light-grey mb-6" }, [
          _c("div", { staticClass: "container mx-auto" }, [
            _c(
              "div",
              { staticClass: "mb-6" },
              [
                _c("fl-show", {
                  attrs: {
                    label: _vm.$t("commons.fields.status"),
                    value: _vm.displayStatus,
                    gridType: "label-2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-6" },
              [
                _c("fl-show", {
                  attrs: {
                    label: _vm.$t("commons.fields.conditions"),
                    value: _vm.movie.conditions,
                    gridType: "label-2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full grid grid-cols-12 gap-x-6 gap-y-5 mb-6" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "col-start-1 col-span-2 my-auto text-sm text-right",
                  },
                  [_vm._v(_vm._s(_vm.$t("commons.fields.selling_price")))]
                ),
                _c("div", { staticClass: "col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [_vm._v("1." + _vm._s(_vm.$t("commons.fields.africa")))]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[0].price))]),
                ]),
                _c("div", { staticClass: "col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [_vm._v("2." + _vm._s(_vm.$t("commons.fields.asia")))]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[1].price))]),
                ]),
                _c("div", { staticClass: "col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [
                      _vm._v(
                        "3." + _vm._s(_vm.$t("commons.fields.the_caribbean"))
                      ),
                    ]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[2].price))]),
                ]),
                _c("div", { staticClass: "col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [
                      _vm._v(
                        "4." + _vm._s(_vm.$t("commons.fields.central_america"))
                      ),
                    ]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[3].price))]),
                ]),
                _c("div", { staticClass: "col-start-3 col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [_vm._v("5." + _vm._s(_vm.$t("commons.fields.europe")))]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[4].price))]),
                ]),
                _c("div", { staticClass: "col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [
                      _vm._v(
                        "6." + _vm._s(_vm.$t("commons.fields.north_america"))
                      ),
                    ]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[5].price))]),
                ]),
                _c("div", { staticClass: "col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [_vm._v("7." + _vm._s(_vm.$t("commons.fields.oceania")))]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[6].price))]),
                ]),
                _c("div", { staticClass: "col-span-2 relative" }, [
                  _c(
                    "p",
                    { staticClass: "text-xxs text-left absolute sub-label" },
                    [
                      _vm._v(
                        "8." + _vm._s(_vm.$t("commons.fields.south_america"))
                      ),
                    ]
                  ),
                  _c("p", [_vm._v(_vm._s(_vm.movie.prices[7].price))]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "mb-6" },
              [
                _c("fl-show", {
                  attrs: {
                    label: _vm.$t("commons.fields.quality_rank"),
                    value: _vm.qualityRank,
                    gridType: "label-2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-6" },
              [
                _c("fl-show", {
                  attrs: {
                    label: _vm.$t("commons.fields.sales_area"),
                    value: _vm.movie.region,
                    gridType: "label-2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-6" },
              [
                _c("fl-show", {
                  attrs: {
                    label: _vm.$t("commons.fields.public_state"),
                    value: _vm.movie.is_public
                      ? _vm.$t("commons.fields.public_state_public")
                      : _vm.$t("commons.fields.public_state_private"),
                    gridType: "label-2",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "mb-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-span-6 col-start-1 grid gap-x-6 grid-cols-12 items-start",
                },
                [
                  _c("label", { staticClass: "col-span-2 text-right" }, [
                    _vm._v(_vm._s(_vm.$t("commons.fields.management_note"))),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "whitespace-pre-line break-words col-span-4",
                    },
                    [_vm._v(_vm._s(_vm.movie.management_note))]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "mb-6" }, [
              _c("p", [
                _vm._v(" " + _vm._s(_vm.providerDisplayName) + "("),
                _c("a", { attrs: { href: "mailto:" + this.provider.email } }, [
                  _vm._v(_vm._s(this.provider.email)),
                ]),
                _vm._v(") "),
              ]),
              _c(
                "button",
                {
                  staticClass: "w-24 px-1 py-1 mr-1 bg-base-yellow font-bold",
                  on: {
                    click: function ($event) {
                      return _vm.impersonate()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("pages.administrators.commons.impersonate")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "container mx-auto" }, [
          _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-4" }, [
            _c("p", { staticClass: "col-span-2 text-right font-bold mb-4" }, [
              _vm._v(_vm._s(_vm.$t("commons.fields.salestalk"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "mb-6" },
            [
              _c("fl-show", {
                attrs: {
                  label: _vm.$t("commons.fields.sns_note"),
                  value: _vm.movie.translations[_vm.$i18n.locale].sns_note,
                  gridType: "label-2",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-6" },
            [
              _c("fl-show", {
                attrs: {
                  label: _vm.$t("commons.fields.news_note"),
                  value: _vm.movie.translations[_vm.$i18n.locale].news_note,
                  gridType: "label-2",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-6" },
            [
              _c("fl-show", {
                attrs: {
                  label: _vm.$t("commons.fields.other_note"),
                  value: _vm.movie.translations[_vm.$i18n.locale].other_note,
                  gridType: "label-2",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2 mb-6 grid grid-cols-6 gap-x-6" },
            [
              _c("p", { staticClass: "label col-span-1 text-right" }, [
                _vm._v(_vm._s(_vm.$t("commons.fields.tag"))),
              ]),
              _c(
                "p",
                {
                  staticClass:
                    "field col-span-5 flex justify-start flex-wrap content-around",
                },
                [
                  _vm._l(_vm.movie.tags, function (tag, key) {
                    return [
                      _c(
                        "div",
                        {
                          key: key,
                          staticClass:
                            "py-1 px-2 mr-2 mb-2 genre-tag-label text-sm text-white",
                        },
                        [_vm._v(" " + _vm._s(tag.name) + " ")]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }