var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu == "cast"
    ? _c("section", [
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-x-6" },
          _vm._l(_vm.castCount, function (value, key) {
            return _c(
              "div",
              { key: key, staticClass: "col-start-1 col-span-1" },
              [
                key == 0
                  ? [
                      _c("fl-show", {
                        class: "mb-6",
                        attrs: {
                          label: _vm.$t("commons.fields.main_cast"),
                          value:
                            _vm.movie.translations[_vm.$i18n.locale][
                              "main_cast_" + value
                            ],
                        },
                      }),
                    ]
                  : _vm.movie.translations[_vm.$i18n.locale][
                      "main_cast_" + value
                    ]
                  ? [
                      _c("fl-show", {
                        class: "mb-6",
                        attrs: {
                          label: " ",
                          value:
                            _vm.movie.translations[_vm.$i18n.locale][
                              "main_cast_" + value
                            ],
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-x-6" },
          [
            _c("fl-show", {
              class: "mb-6",
              attrs: {
                label: _vm.$t("commons.fields.director"),
                value: _vm.movie.translations[_vm.$i18n.locale].director,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-x-6" },
          [
            _c("fl-show", {
              class: "mb-6",
              attrs: {
                label: _vm.$t("commons.fields.copyright_holder"),
                value:
                  _vm.movie.translations[_vm.$i18n.locale].copyright_holder,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-x-6" },
          [
            _c("fl-show", {
              class: "mb-6",
              attrs: {
                label: _vm.$t("commons.fields.creator"),
                value: _vm.movie.translations[_vm.$i18n.locale].creator,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-x-6" },
          [
            _c("fl-show", {
              class: "mb-6",
              attrs: {
                label: _vm.$t("commons.fields.script_writer"),
                value: _vm.movie.translations[_vm.$i18n.locale].script_writer,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-x-6" },
          [
            _c("fl-show", {
              class: "mb-6",
              attrs: {
                label: _vm.$t("commons.fields.cinematographer"),
                value: _vm.movie.translations[_vm.$i18n.locale].cinematographer,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "grid grid-cols-2 gap-x-6" },
          [
            _c("fl-show", {
              class: "mb-6",
              attrs: {
                label: _vm.$t("commons.fields.music"),
                value: _vm.movie.translations[_vm.$i18n.locale].music,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }