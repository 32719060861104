var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu == "format"
    ? _c(
        "section",
        [
          _c("fl-show", {
            staticClass: "mb-6",
            attrs: {
              label: _vm.$t("commons.fields.movie_formats_movie_size"),
              gridType: "label-2",
              value: _vm.movie.main_video.videos[0].resolution,
              options: _vm.movieResolution,
            },
          }),
          _c("fl-show", {
            class: "mb-6",
            attrs: {
              label: _vm.$t("commons.fields.framerate"),
              gridType: "label-2",
              value: _vm.movie.main_video.videos[0].frame_rate,
              options: _vm.frameRate,
            },
          }),
          _c("fl-show", {
            class: "mb-6",
            attrs: {
              label: _vm.$t("commons.fields.aspect_ratio"),
              gridType: "label-2",
              value: _vm.movie.main_video.videos[0].aspect_ratio,
              options: _vm.aspectRatio,
            },
          }),
          _c("fl-show", {
            class: "mb-6",
            attrs: {
              label: _vm.$t("commons.fields.audio_format"),
              gridType: "label-2",
              value: _vm.movie.main_video.videos[0].audio_format,
              options: _vm.audioFormat,
            },
          }),
          _c(
            "div",
            { staticClass: "grid grid-cols-12 gap-x-6" },
            [
              _c(
                "FlMovieItem",
                {
                  attrs: {
                    label: _vm.$t(
                      "commons.fields.movie_languages_voice_channel"
                    ),
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.movie.main_video.videos[0].audio_channel) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("fl-show", {
            class: "mb-6",
            attrs: {
              label: _vm.$t("commons.fields.movie_formats_format"),
              gridType: "label-2",
              value: _vm.movie.main_video.videos[0].video_encoding_format,
              options: _vm.filmFormat,
            },
          }),
          _c("fl-show", {
            class: "mb-6",
            attrs: {
              label: _vm.$t("commons.fields.s_o_m"),
              gridType: "label-2",
              value: _vm.movie.s_o_m,
            },
          }),
          _c("fl-show", {
            class: "mb-6",
            attrs: {
              label: _vm.$t("commons.fields.movie_formats_movie_time"),
              gridType: "label-2",
              value: _vm.movie.duration,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }