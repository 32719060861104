var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu == "award"
    ? _c("section", [
        _c(
          "div",
          { staticClass: "w-full mb-6" },
          [
            _c("fl-showAward", {
              attrs: {
                label: _vm.$t("commons.fields.awards"),
                value: _vm.movie.awards,
                fieldClass: "block",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mb-6" },
          [
            _c("fl-showHistory", {
              attrs: {
                label: _vm.$t("commons.fields.screening"),
                value: _vm.movie.play_histories,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mb-6" },
          [
            _c("fl-showHistory", {
              attrs: {
                label: _vm.$t("commons.fields.vods"),
                value: _vm.movie.svod_histories,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }