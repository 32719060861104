var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu == "gallery"
    ? _c(
        "section",
        { staticClass: "grid grid-cols-12 gap-x-6 gap-y-8" },
        [
          _c("div", { staticClass: "col-span-12 text-left" }, [
            _c("p", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.$t("commons.fields.main_visual"))),
            ]),
          ]),
          _vm.featuredImage || _vm.screenShotImages.length > 0
            ? [
                _vm.featuredImage
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-span-4 pt-16_9 h-0 bg-gray-300 relative",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "display-block absolute inset-0" },
                          [
                            _c("img", {
                              staticClass:
                                "w-full h-full object-cover object-center",
                              attrs: { src: _vm.featuredImage },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.screenShotImages, function (image, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "col-span-4 pt-16_9 h-0 bg-gray-300 relative",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "display-block absolute inset-0" },
                        [
                          _c("img", {
                            staticClass:
                              "w-full h-full object-cover object-center",
                            attrs: { src: image.file.url },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
              ]
            : [_vm._m(0)],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-span-3 relative" }, [
      _c("p", [_vm._v("登録画像がありません。")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }