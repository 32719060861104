var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen" }, [
    _vm.movie.uuid
      ? _c(
          "div",
          { staticClass: "title grid grid-cols-12 gap-x-6 container mx-auto" },
          [
            _c("div", { staticClass: "col-span-11 mt-2 text-left" }, [
              _c("h1", { staticClass: "font-bold word-wrap text-justify" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.movie.translations[_vm.$i18n.locale].film_name) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "text-sm py-2" }, [
                _vm._v(" " + _vm._s(_vm.movieOutline) + " "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _vm.loginUserRole !== "consumer"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "w-full mt-4",
                        attrs: {
                          tag: "button",
                          to: "/ch/movies/edit/" + this.movieUuid,
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-full font-semibold h-12 bg-off-yellow",
                          },
                          [_vm._v("編集")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "grid grid-cols-12 gap-x-6 container mx-auto" }, [
      _c(
        "div",
        { class: _vm.featuredImageIsPortrait ? "col-span-5" : "col-span-7" },
        [
          this.movie.featured_images.length
            ? _c("img", {
                ref: "headerImage",
                staticClass: "w-full",
                attrs: {
                  id: "featured_image",
                  src: this.movie.featured_images[0].file.url,
                },
                on: {
                  load: function ($event) {
                    return _vm.onImageLoaded()
                  },
                },
              })
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          ref: "headerOther",
          staticClass: "flex flex-col",
          class: _vm.featuredImageIsPortrait ? "col-span-7" : "col-span-5",
        },
        [
          _c("div", { staticClass: "w-full mb-4" }, [
            _c("ul", { staticClass: "w-full flex items-center" }, [
              _vm.movie.play_history && _vm.movie.play_history.length
                ? _c(
                    "li",
                    { staticClass: "h-14 w-14 object-fill inline-block mr-3" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/image/icon_award-08.svg"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.movie.awards && _vm.movie.awards.length
                ? _c(
                    "li",
                    { staticClass: "h-14 w-14 object-fill inline-block mr-3" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/image/icon_award-01.svg"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.isNew
                ? _c(
                    "li",
                    { staticClass: "h-14 w-14 object-fill inline-block mr-3" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/image/icon_award-02.svg"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.movie.svod_history && _vm.movie.svod_history.length
                ? _c(
                    "li",
                    { staticClass: "h-14 w-14 object-fill inline-block mr-3" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/image/icon_award-03.svg"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "w-full flex-auto overflow-scroll" }, [
            _c("p", {
              staticClass: "word-wrap text-justify",
              domProps: {
                innerHTML: _vm._s(
                  _vm.movie.translations[_vm.$i18n.locale].outline
                ),
              },
            }),
          ]),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "w-full" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toggleFavorite()
                    },
                  },
                },
                [
                  !_vm.movie.is_favorite
                    ? _c(
                        "svg",
                        {
                          staticClass: "w-10 h-10",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            "stroke-width": "1.5",
                            stroke: "currentColor",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z",
                            },
                          }),
                        ]
                      )
                    : _c("span", { staticClass: "text-mango" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "w-10 h-10 fill-current",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                              fill: "currentColor",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z",
                              },
                            }),
                          ]
                        ),
                      ]),
                ]
              ),
            ]),
            _vm.movie.uuid && _vm.isExistCart
              ? _c(
                  "div",
                  { staticClass: "col-start-3 col-span-1 text-xxs mt-1" },
                  [
                    _vm._v(
                      " ※" +
                        _vm._s(
                          _vm.$t("pages.consumers.movie_view.already_in_cart")
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _vm.loginUserRole !== "provider"
                  ? _c(
                      "LinkButton",
                      {
                        attrs: {
                          classList:
                            "h-14 py-3 px-2 xl:text-lg md:text-sm tracking-widest",
                          disabled: _vm.isExistCart,
                        },
                        on: { clickButton: _vm.addToQuote },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("commons.fields.request_quote")) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _vm.movie.uuid
      ? _c("div", [
          _c("div", {
            staticClass: "container mx-auto grid grid-cols-3 gap-x-6 mb-4",
          }),
        ])
      : _vm._e(),
    _c("div", { staticClass: "border-b border-gray-600 mb-4" }),
    _vm.movie.uuid
      ? _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass:
                  "movie_preview container flex justify-between mx-auto mt-8",
              },
              [
                _c("div", { staticClass: "w-5/12" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _c("h4", { staticClass: "mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("commons.fields.trailer"))),
                    ]),
                  ]),
                  _vm.movie.trailer_url
                    ? _c("div", { staticClass: "mb-10" }, [
                        _c("div", { staticClass: "text-left" }, [
                          _c("iframe", {
                            staticClass: "w-full h-64",
                            attrs: {
                              src: _vm.trailerUrl,
                              frameborder: "0",
                              allow: "autoplay; encrypted-media",
                              allowfullscreen: "",
                            },
                          }),
                        ]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 mb-10 w-full h-64 bg-gray-300 p-4",
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "place-self-center text-center" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pages.commons.movies.request_screener_trailer"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                ]),
                _c("div", { staticClass: "w-5/12" }, [
                  _c("h4", { staticClass: "mb-4" }, [
                    _vm._v(_vm._s(_vm.$t("commons.fields.screener"))),
                  ]),
                  _vm.movie.screener_url
                    ? _c("div", [
                        _c("div", { staticClass: "text-left" }, [
                          _c("iframe", {
                            staticClass: "w-full h-64",
                            attrs: {
                              src: _vm.screenerUrl,
                              frameborder: "0",
                              allow: "autoplay; encrypted-media",
                              allowfullscreen: "",
                            },
                          }),
                          _vm.movie.screener_url
                            ? _c("div", [
                                _c("span", [_vm._v("スクリーナー：")]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "underline",
                                    attrs: {
                                      href: _vm.movie.screener_url,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.movie.screener_url)),
                                    ]),
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "w-5 h-5 pb-1 inline",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          fill: "none",
                                          viewBox: "0 0 24 24",
                                          "stroke-width": "1.5",
                                          stroke: "currentColor",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.movie.screener_password &&
                          _vm.movie.screener_password.length
                            ? _c(
                                "div",
                                { staticClass: "bg-gray-200 rounded mt-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full content-center grid grid-cols-2 gap-x-4",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "col-span-1 w-full mx-auto text-center bg-off-yellow hover:bg-off-yellow-lighter hover:underline focus:bg-off-yellow-darker rounded",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$modal.show(
                                                "beforeCopyPassword"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-bold text-center py-2 mx-auto",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.movies.copy_password"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "select-none text-center my-2",
                                        },
                                        [
                                          _vm._v(
                                            'password is "' +
                                              _vm._s(
                                                _vm.movie.screener_password
                                              ) +
                                              '"'
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.movie.screener_password &&
                          _vm.movie.screener_password.length
                            ? _c("div", { staticClass: "w-full" }, [
                                _c("div", { staticClass: "my-2 text-xs" }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.movies.copy_password_explain_title"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.movies.copy_password_explain_01"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "pages.commons.movies.copy_password_explain_02"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 mb-10 w-full h-64 bg-gray-300 p-4",
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "place-self-center text-center" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pages.commons.movies.request_screener_trailer"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                ]),
              ]
            ),
            _c("div", { staticClass: "border-t border-brown-grey" }),
            _c("MovieDetailMenu", {
              attrs: { activeMenu: _vm.activeMenu },
              on: { "active-page": _vm.changeActive },
            }),
            _c("div", { staticClass: "border-b border-brown-grey" }),
            _c(
              "div",
              { staticClass: "movie_detail container mx-auto pt-4 mb-10" },
              [
                _c("Outline", {
                  attrs: { movie: _vm.movie, activeMenu: _vm.activeMenu },
                }),
                _c("Detail", {
                  attrs: { movie: _vm.movie, activeMenu: _vm.activeMenu },
                }),
                _c("Format", {
                  attrs: { movie: _vm.movie, activeMenu: _vm.activeMenu },
                }),
                _c("Cast", {
                  attrs: { movie: _vm.movie, activeMenu: _vm.activeMenu },
                }),
                _c("Gallery", {
                  attrs: { movie: _vm.movie, activeMenu: _vm.activeMenu },
                }),
                _c("Award", {
                  attrs: { movie: _vm.movie, activeMenu: _vm.activeMenu },
                }),
                _c("Data", {
                  attrs: { movie: _vm.movie, activeMenu: _vm.activeMenu },
                }),
              ],
              1
            ),
            _c("NoticeModal", { attrs: { modalName: "addedToCart" } }, [
              _c("div", { staticClass: "w-full" }, [
                _c("p", [
                  _vm._v(
                    "Added " +
                      _vm._s(
                        _vm.movie.translations[this.$i18n.locale].film_name
                      ) +
                      " to cart."
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "px-2 py-2 w-full h-full relative text-center bg-base-yellow font-bold mb-4",
                    on: {
                      click: function ($event) {
                        return _vm.closeShowCart(false)
                      },
                    },
                  },
                  [_c("span", [_vm._v("keep looking around")])]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "px-2 py-2 w-full h-full relative text-center bg-base-yellow font-bold mb-4",
                    on: {
                      click: function ($event) {
                        return _vm.closeShowCart(true)
                      },
                    },
                  },
                  [_c("span", [_vm._v("go to cart")])]
                ),
              ]),
            ]),
            _c(
              "NoticeModal",
              { attrs: { modalName: "errorAddCart", buttons: ["close"] } },
              [
                _c("div", { staticClass: "w-full" }, [
                  _c("p", [
                    _vm._v(
                      "Unable to add " +
                        _vm._s(
                          _vm.movie.translations[this.$i18n.locale].film_name
                        ) +
                        " to cart."
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "NoticeLinkModal",
              { attrs: { modalName: "beforeCopyPassword" } },
              [
                _c("div", { staticClass: "w-full" }, [
                  _c("p", [
                    _vm._v(
                      " Screener and password are confidential information. Please handle it with due care so as not to leak it to a third party. In case of leakage, you may incur liability for damages. "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " By pressing the below button, the screener password will be copied to your clipboard so you will be able to paste and use it. "
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-2 py-2 w-full h-full relative text-center bg-base-yellow font-bold mb-4",
                      on: { click: _vm.copyPassword },
                    },
                    [_vm._v(" " + _vm._s(_vm.copyButtonText) + " ")]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }