var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full aspect-w-16 aspect-h-9" }, [
    _vm.value && !_vm.hasError
      ? _c("div", { staticClass: "w-full h-full overflow-hidden" }, [
          _c("img", {
            staticClass:
              "w-full h-full object-cover object-center overflow-hidden",
            class: _vm.isCorrectProportion
              ? ""
              : "filter blur-sm transform scale-105",
            attrs: { src: _vm.value, id: "movie_image" },
            on: {
              error: function ($event) {
                _vm.hasError = true
              },
              load: function ($event) {
                return _vm.onImageLoaded()
              },
            },
          }),
        ])
      : _c(
          "div",
          {
            staticClass:
              "h-full w-full object-cover object-center bg-light-grey",
          },
          [
            _c("img", {
              staticClass: "h-full w-1/3 m-auto align-middle",
              attrs: { src: require("@/assets/image/logo_gray.svg") },
            }),
          ]
        ),
    _vm.value && !_vm.hasError && !_vm.isCorrectProportion
      ? _c("img", {
          staticClass: "inset-0 object-contain object-center absolute",
          attrs: { src: _vm.value },
        })
      : _vm._e(),
    _c("div", { staticClass: "absolute inset-0" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }