var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        "p",
        {
          staticClass: "text-right mb-6 col-start-1",
          class: _vm.labelRowHeight + " col-span-" + _vm.titleColSpan,
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm.options && _vm.options.length > 0
        ? [
            _c(
              "p",
              {
                class:
                  "col-span-" +
                  (_vm.gridCols - _vm.titleColSpan) +
                  " " +
                  _vm.textClass,
              },
              [_vm._v(" " + _vm._s(_vm.optionDisplayValue) + " ")]
            ),
          ]
        : _vm.valueIsArray
        ? [
            _vm._l(_vm.value, function (item, index) {
              return [
                _c(
                  "p",
                  {
                    key: index,
                    class:
                      "col-span-" +
                      (_vm.gridCols - _vm.titleColSpan) +
                      " col-start-" +
                      (_vm.titleColSpan + 1) +
                      " " +
                      _vm.textClass,
                  },
                  [
                    _vm._t(
                      "default",
                      function () {
                        return [
                          _vm._v(
                            " " + _vm._s(item) + " " + _vm._s(_vm.unit) + " "
                          ),
                        ]
                      },
                      { item: item }
                    ),
                  ],
                  2
                ),
              ]
            }),
          ]
        : [
            _c(
              "p",
              {
                class:
                  "col-span-" +
                  (_vm.gridCols - _vm.titleColSpan) +
                  "  " +
                  _vm.textClass,
              },
              [_vm._t("default"), _vm._v(" " + _vm._s(_vm.unit) + " ")],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }